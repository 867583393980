.navbar{
    // background: linear-gradient(rgb(103, 26, 191) 20%, rgb(160, 50, 145) 70%, rgb(210, 64, 137));
    background-color: #007bff;
    padding: 5px 10px;
    .text-white{
        width: 100%;
    }
    .marquee-container {
        width: 75%; 
        overflow: hidden;
        color: white;
        font-size: 20px; 
        font-weight: bold; 
        padding-top: 5px;
        height: 40px; 
        position: relative; 
        bottom: 0;
        .marquee {
          display: inline-block; 
          white-space: nowrap; 
          position: absolute; 
          animation: marquee-scroll 10s linear infinite;
        }
      }
}
@keyframes marquee-scroll  {
    0% {
      transform: translateX(300%);
    }
    100% {
      transform: translateX(-100%);
    }
  }