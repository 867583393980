.call-container {
  display: flex;
  justify-content: space-between;

  .table {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .column {
      flex: 1;
      display: flex;
      flex-direction: column;

      .header,
      .row {
        display: grid;
        grid-template-columns:  2fr 3fr 3fr 2fr; 
        gap: 0;
        align-items: center;
        text-align: center;
        border: 1px solid #ddd;
        box-sizing: border-box;
      }

      .header {
        background-color: #007bff;
        color: white;
        font-weight: bold;
        text-transform: uppercase;

        div {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          font-size: 30px;
          height: 100%;
        }
      }

      .row {
        font-size: 20px;
        background-color: #f9f9f9;
        transition: background-color 0.3s;
        width: 100%;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          font-size: 33px;
          border-right: 1px solid #ddd;
          height: 100%;
          width: 100%;
        }
        .truncate {
          display: block;
          overflow: hidden; 
          white-space: nowrap; 
          text-overflow: ellipsis; 
        
          // p {
          //   margin: 0; /* Removes default margin from <p> */
          //   padding: 0; /* Ensures no padding disrupts the layout */
          // }
        }

        &:nth-child(even) {
          background-color: #e8f5e9;
        }

        &:hover {
          background-color: #d7ebf9;
        }
      }

      .row:nth-child(odd) {
        background-color: #d4f8e8;
      }
    }
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .call-container {
    .table {
      .column {
        .header,
        .row {
          font-size: 28px;
        }
      }
    }
  }
}

@media (min-width: 2560px) {
  .call-container {
    .table {
      .column {
        .header,
        .row {
          font-size: 36px;
        }
      }
    }
  }
}


@keyframes fadeInOut {
  0% {
    background-color: #d4f8e8;
  }

  50% {
    background-color: #b0f2dc;
  }

  100% {
    background-color: transparent;
  }
}


@media (min-width: 1920px) and (max-width: 2560px) {
  .call-container {
    .table {
      .column {
        .header {
          font-size: 36px; // Larger text for Full HD
          // padding: 20px 0;
        }

        .row {
          font-size: 30px; // Larger text for rows
          > div {
            // padding: 15px 10px; // Adjust padding for readability
          }
        }
      }
    }
  }
}

/* Media Query for 4K (3840x2160) */
@media (min-width: 2560px) {
  .call-container {
    .table {
      .column {
        .header {
          font-size: 48px; // Even larger text for 4K
          padding: 25px 0;
        }

        .row {
          font-size: 40px;
          > div {
            // padding: 20px 15px;
          }
        }
      }
    }
  }
}



// .call-table-container {
//   text-align: center;
//   font-family: Arial, sans-serif;
//   width: 100%;
//   .table {
//     display: flex;
//     justify-content: space-between;
//     align-items: start;
//     width: 100%;

//     .call-table {
//       flex: 1; /* Ensure equal width for both tables */
//       margin: 0 auto;
//       border-collapse: collapse;
//       font-size: 14px;
//       background-color: #f9f9f9;
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

//       th,
//       td {
//         border: 1px solid #ddd;
//         font-size: 28px;
//         padding: 12px; /* Ensure consistent padding */
//         text-align: center;
//         width: 16.66%; /* Equal width for all columns */
//       }

//       th {
//         background-color: #007bff;
//         color: white;
//         font-weight: bold;
//         text-transform: uppercase;
//       }

//       tbody tr {
//         transition: background-color 0.3s ease;
//       }

//       tbody tr:nth-child(even) {
//         background-color: #f2f2f2;
//       }

//       tbody tr:hover {
//         background-color: #d7ebf9;
//       }

//       .expired-row {
//         background-color: #f8d7da;
//         color: #721c24;
//         text-align: center;
//       }

//       .call-item-accept {
//         background-color: green;
//         border: none;
//         color: white;
//         padding: 5px 10px;
//         border-radius: 4px;
//         cursor: pointer;
//         font-size: 14px;
//         transition: background-color 0.3s ease;

//         &:hover {
//           background-color: darkgreen;
//         }
//       }

//       .new-row {
//         animation: fadeInOut 2s ease-in-out;
//         background-color: #d4f8e8;
//       }
//     }
//   }
// }

// @keyframes fadeInOut {
//   0% {
//     background-color: #d4f8e8;
//   }

//   50% {
//     background-color: #b0f2dc;
//   }

//   100% {
//     background-color: transparent;
//   }
// }

// // Responsive Breakpoints for HD (720p) and Full HD (1080p)
// @media (min-width: 1000px) and (max-width: 1920px) {
//   .call-table-container {
//     .call-table {
//       th,
//       td {
//         font-size: 24px; /* Adjust font size for HD screens */
//         padding: 12px;
//       }
//     }
//   }
// }

// // Responsive Breakpoints for 4K or larger screens
// @media (min-width: 1921px) {
//   .call-table-container {
//     .call-table {
//       th,
//       td {
//         font-size: 32px; /* Adjust font size for 4K screens */
//         padding: 16px;
//       }
//     }
//   }
// }


