.socket-modal{
    background-color: red !important;
}

.ReactModal__Content {
    inset: 30% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


.box{
    text-align: center;
}

.box .title{
    font-size: 25px;
}

.box .button{
    background-color: var(--color-light-gray);
  padding: 6px 20px;
  color: var(--color-dark-blue);
  border-radius: 20px;
  cursor: pointer;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  margin-top: 20px;
}

.box .button:hover {
    background-color: var(--color-red);
    color: #fff;  
}